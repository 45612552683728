import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import i18n from '../../i18n';
import axios from "axios";

class PricingV3 extends Component {

	state = {
		numberOfUsers: null,
		error: null
	  };

	componentDidMount()
	{
		// Effectuer la requête GET lorsque le composant est monté
		axios.get("https://api.live-show.app/user/get_premium_number_of_users")
		.then(response => {
			// Mettre à jour l'état avec le nombre d'utilisateurs
			this.setState({ numberOfUsers: response.data });
		})
		.catch(error => {
			// Gérer les erreurs
			this.setState({ error: error.message });
		});
	}

  render() {

      let publicUrl = process.env.PUBLIC_URL+'/'
      let imagealt = 'image'

      const { numberOfUsers, error } = this.state;

    return  <section className="pricing-two pricing-two-2">
        <div className="container">
          <div className="row flex__center">
            <div className="col-lg-4">
              <div className="pricing-two__single">
                <div className="pricing-two__top">
                  <div className="pricing-two__bubble-1" />{/* /.pricing-two__bubble-1 */}
                  <div className="pricing-two__bubble-2" />{/* /.pricing-two__bubble-2 */}
                  <div className="pricing-two__bubble-3" />{/* /.pricing-two__bubble-3 */}
                  <div className="pricing-two__bubble-4" />{/* /.pricing-two__bubble-4 */}
                  <img src={publicUrl+"assets/images/shapes/pricing-shape-1-1.png"} className="pricing-two__shape" alt={ imagealt } />
                  <h3>{i18n.t("free_usage")}</h3>
                  <img src={publicUrl+"assets/images/shapes/pricing-i-2-1.png"} alt={ imagealt } />
                </div>{/* /.pricing-two__top */}
                <div className="pricing-two__bottom">
                  <ul className="list-unstyled pricing-two__list">
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_actions")}</li>
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_sounds")}</li>
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_widgets")}</li>
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_stream_keys")}</li>
                    <li><i className="far fa-times" style={{transform: "scale(1.2)", marginLeft: "2px", marginRight: "15px"}}/>{i18n.t("live_match_gaming")}</li>
                  </ul>{/* /.list-unstyled pricing-two__list */}
                  <p> {i18n.t("free")} </p>
                  <div className="text-center">
                    <Link to="/download" className="thm-btn pricing-two__btn">{i18n.t("download")} <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn pricing-one__btn */}
                    <h5 className='pricing__no-CB-ask' style={{ marginBottom: '17px' }}>{i18n.t("no_cb_ask")}</h5>
                  </div>{/* /.text-center */}
                </div>{/* /.pricing-two__bottom */}
              </div>{/* /.pricing-two__single */}
            </div>{/* /.col-lg-4 */}
            <div className="col-lg-4" >
              <div className="pricing-two__single" >
                <div className="pricing-two__top">
                  <div className="pricing-two__bubble-1" />{/* /.pricing-two__bubble-1 */}
                  <div className="pricing-two__bubble-2" />{/* /.pricing-two__bubble-2 */}
                  <div className="pricing-two__bubble-3" />{/* /.pricing-two__bubble-3 */}
                  <div className="pricing-two__bubble-4" />{/* /.pricing-two__bubble-4 */}
                  <img src={publicUrl+"assets/images/shapes/pricing-shape-1-1.png"} className="pricing-two__shape" alt={ imagealt } />
                  <h3>{i18n.t("premium")}</h3>
                  
                  <img src={publicUrl+"assets/images/shapes/pricing-i-2-3.png"} alt={ imagealt } />
                </div>{/* /.pricing-two__top */}
                <div className="pricing-two__bottom">
                  <ul className="list-unstyled pricing-two__list">
                    <li><i className="far fa-check" />{i18n.t("unlimited_actions")}</li>
                    <li><i className="far fa-check" />{i18n.t("unlimited_sounds")}</li>
                    <li><i className="far fa-check" />{i18n.t("unlimited_widgets")}</li>
                    <li><i className="far fa-check" />{i18n.t("unlimited_stream_keys")}</li>
                    <li><i className="far fa-check" />{i18n.t("live_match_gaming")}</li>
                  </ul>{/* /.list-unstyled pricing-two__list */}
                  <p> 19.99 <b>€</b> <span>{i18n.t("monthly")}</span></p>
                  <div className="text-center">
                    <Link to="/download" className="thm-btn pricing-two__btn">{i18n.t("choose_plan")} <i className="fa fa-angle-double-right" /></Link>{/* /.thm-btn pricing-one__btn */}
                  </div>{/* /.text-center */}
                  <h3 className='pricing__no-CB-ask'>+{numberOfUsers} {i18n.t("premium_user")}</h3>
                </div>{/* /.pricing-two__bottom */}
              </div>{/* /.pricing-two__single */}
              
            </div>{/* /.col-lg-4 */}
            <h5 style={{paddingTop:"15px"}}>{i18n.t("offer_without_engagement")}</h5>
            {/* Extented
            <div className="col-lg-4" style={{cursor: 'not-allowed'}}>
              <div className="pricing-two__single" style={{pointerEvents: 'none', filter: 'brightness(0.8)'}}>
                <div className="pricing-two__top">
                  <div className="pricing-two__bubble-1" />
                  <div className="pricing-two__bubble-2" />
                  <div className="pricing-two__bubble-3" />
                  <div className="pricing-two__bubble-4" />
                  <img src={publicUrl+"assets/images/shapes/pricing-shape-1-1.png"} className="pricing-two__shape" alt={ imagealt } />
                  <h3>Extended</h3>
                  <img src={publicUrl+"assets/images/shapes/pricing-i-2-2.png"} alt={ imagealt } />
                </div>
                <div className="pricing-two__bottom">
                  <ul className="list-unstyled pricing-two__list">
                  <li><i className="far fa-check" />No Limit Actions</li>
                    <li><i className="far fa-check" />No Limit Uploads Sounds</li>
                    <li><i className="far fa-check" />No Limit Widgets</li>
                    <li><i className="far fa-check" />Live Support</li>
                  </ul>
                  <p><b>$</b> 34.99 <span>Monthly</span></p>
                  <div className="text-center">
                  <Link to="/download" className="thm-btn pricing-two__btn">Choose Plan <i className="fa fa-angle-double-right" /></Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>{/* /.row */}
        </div>{/* /.container */}
      </section>


        }
}

export default PricingV3