import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import i18n from '../../i18n';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class VideoV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="pricing-landing-page">
              <h3> <Trans i18nKey="title_pricing_lp" /> </h3>
              <div className='pricingLP_wrapper'>
                <div className='pricingLP_item pricingLP_free'>
                  <span className='pricingLP_iconWrapper pricingLP_icon_free'>
                    <i class="fas fa-leaf"></i>
                  </span>
                  <h2> <Trans i18nKey="free_usage" /> </h2>
                  <span className='pricingLP_infoPrice'>
                    <h3><Trans i18nKey="free" /></h3>
                    <p><Trans i18nKey="no_cb_ask" /> </p>
                  </span>
                  <span className='pricingLP_separator'></span>
                  <ul className="list-unstyled pricingLP_listFeatures">
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_actions")}</li>
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_sounds")}</li>
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_widgets")}</li>
                    <li><i className="far fa-exclamation-circle" />{i18n.t("limit_stream_keys")}</li>
                    <li><i className="far fa-times" style={{transform: "scale(1.2)", marginLeft: "2px", marginRight: "15px"}}/>{i18n.t("live_match_gaming")}</li>
                  </ul>
                  <Link to="/register" className="thm-btn"> {i18n.t('button_pricing')} </Link>
                </div>
                <div className='pricingLP_item pricingLP_premium'>
                  <span className='pricingLP_iconWrapper pricingLP_icon_premium'>
                    <i class="fas fa-gem"></i>
                  </span>
                  <h2> <Trans i18nKey="premium" /> </h2>
                  <span className='pricingLP_infoPrice'>
                    <h3>19.99€</h3>
                    <p><Trans i18nKey="monthly" /> </p>
                  </span>
                  <span className='pricingLP_separator'></span>

                  <ul className="list-unstyled pricingLP_listFeatures">
                    <li><i className="far fa-check" />{i18n.t("unlimited_actions")}</li>
                    <li><i className="far fa-check" />{i18n.t("unlimited_sounds")}</li>
                    <li><i className="far fa-check" />{i18n.t("unlimited_widgets")}</li>
                    <li><i className="far fa-check" />{i18n.t("unlimited_stream_keys")}</li>
                    <li><i className="far fa-check" />{i18n.t("live_match_gaming")}</li>
                  </ul>
                  <Link to="/register" className="thm-btn"> {i18n.t('button_pricing')} </Link>
                </div>
              </div>
            </section>


        }
}

export default VideoV2