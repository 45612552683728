import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Cookies from 'js-cookie';

function getBrowserLang() {
    const cookie = Cookies.get("language")
    if(cookie)
        return cookie

    const userLanguage = navigator.language; // obtenir la langue de l'utilisateur en minuscules
    if (userLanguage.startsWith('fr')) {
        return 'fr'; // si la langue est le français
    } else if (userLanguage.startsWith('it')) {
        return 'it'; // si la langue est l'italien
    } else {
        return 'en'; // pour toutes les autres langues, retourner l'anglais par défaut
    }
}

i18n
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: getBrowserLang(),
        interpolation: {
            escapeValue:false,
        },

        resources: {
            en: {
                translation: {
                    /* navbar */
                    home: "Home",
                    about_us: "About us",
                    download: "Download",
                    pricing: "Pricing",
                    news: "News",
                    faq: "FAQ",
                    ask_questions: "Ask Questions",
                    language:"Language",
                    create_account:"Create account",

                    /* footer */
                    feature: "Features",
                    terms_of_use: "Terms Of Use",
                    privacy_policy: "Privacy Policy",
                    refund_policy: "Refund Policy",
                    company:"Company",
                    footer_banner_p:"Reinvent your live broadcasts with our software. A powerful customization, unique interactions.",

                    /* why-choose-us */
                    why_choose_us:"Why Like Us",      
                    next_level:"Next Level",
                    next_level_sentence:"Passes le NEXT LEVEL avec ta communauté grâce à Live show",
                    next_level_p:"Live Show provide multiple integrations like Minecraft, Keystrokes Simulations, TTS, Sound Alert and more.",
                    crazy_lives:"Crazy Lives",
                    crazy_lives_sentence:"Rends ton audience complètement CRAZY à tes LIVES !",
                    crazy_lives_p:"Live Show is designed to provide a seamless and user-friendly experience, making it an intuitive tool that caters to your needs with simplicity at its core.",
                    game_changer:"Game Changer",
                    game_changer_sentence:"Live Show, l'outil GAME CHANGER pour tes lives",
                    game_changer_p:"Live Show provides a powerful dashboard, allowing you to view your chat, news feed and much more.",
                    
                    /* cta-v10 */
                    cta10_title:"Start With Live Show",
                    cta10_p:"Download Live Show right now and start working with the best software to create interactions with your community",
                
                    /* testimonial-style-one */
                    clients_say:"Clients Say",
                    clients_say2_1: "Our users <strong>testify</strong>",
                    know_everything_about:"Know Everything About",
                    client1_role:"TikTok Streamer",
                    client1_p: "Fast and intuitive software, visually clean and organized with an accessible dashboard.",
                    client2_p: "Superb interactive app to liven up your lives, highly recommended to everyone, download without hesitation!",
                    client3_p: "Excellent practical app with many features, regular updates, and full translation into English.",
                  
                    /* about-style-one */
                    our_features:"Our Features",
                    our_features_title:"Improve your live satisfaction at all hours.",
                    our_features_p:"Live Show, the amazing live streaming tool on TikTok and Twitch, offers an immersive experience for streamers. With interactive widgets and the ability for viewers to control the live studio, Live Show raises the level of live interaction on TikTok. Features like adding TNT rain to Minecraft live demonstrate Live Show's versatility in creating captivating content.",

                    /* about-style-two */
                    what_we_do:"What We Do",
                    what_we_do_title:"We Offer Best Software For Your Interactions",
                    what_we_do_p_0:"Live Show allow you to reinvent your live broadcasts with our software. A powerful customization, unique interactions. Discover the new world of streaming.",
                    what_we_do_subtitle_1:"Seamless Integration",
                    what_we_do_p_1:"Easily integrate interactive widgets into your streams to captivate your audience.",
                    what_we_do_subtitle_2:"Enhanced Viewer Experience",
                    what_we_do_p_2:"Make your streaming experience more enjoyable and engaging for viewers with dynamic content.",

                    /* banner-v4 */
                    success_your_lives:"success your Lives",
                    home_banner_title_1:"Transform Your Lives",
                    home_banner_title_2:"with Live Show",
                    home_banner_p: "<strong>Live Show</strong>, the incredible live broadcasting tool on <strong>TikTok and Twitch</strong>, offers an <strong>immersive experience for streamers</strong>. With interactive widgets and the ability for viewers to control the live studio, Live Show elevates the level of live interaction on TikTok.",
                    home_banner_download_now:"Download Now",
                    start_now:"Start now",
                    wave_bottom_1: "Increase overall engagement",
                    wave_bottom_2: "Create more interactions with your viewers",
                    home_banner_info_list_1: "Enhance <strong>interaction</strong>",
                    home_banner_info_list_2: "Foster <strong>engagement</strong>",
                    home_banner_info_list_3: "Facilitate <strong>collaboration</strong>",
                    home_banner_info_list_4: "Seamless <strong>integration</strong>",


                    /* banner-download */
                    for_more_interactions:"for more interactions",
                    users:"Users",
                    free_for_life:"It's free for life :) !",

                    /* about-v6 */
                    aboutv6_title:"Create an engaged community",
                    aboutv6_p_0:"Live Show allow you to create more interaction between you and your viewers. Start now, build your own community.",
                    aboutv6_subtitle_1:"Interactions Tools",
                    aboutv6_p_1:"Live Show allow to create an interaction in one minute.",
                    aboutv6_subtitle_2:"App Integrations",
                    aboutv6_p_2:"Create widget, Minecraft Interactions, Keystrokes Simulations.",

                    /* about */
                    about_us_title:"Perfect place to transform your live stream.",
                    about_us_p_0:"Live Show, the amazing live streaming tool on TikTok and Twitch, offers an immersive experience for streamers.",
                    about_us_li_1:"Live Show is a free application, you can download it via Steam!",
                    about_us_li_2:"Live Show is trusted by a lot of content creators in the world !",


                    /*FAQ*/
                    about_our_company: "About Our Company",
                    how_it_work: "How Work Live Show",
                    we_are_pro: "Know Something Us. We Are Professional",
                    faq_q1: "Is Live Show Secure ?",
                    faq_q1_answer: "Yes we pay attention to data security. we use an hash system for your password and your banking information is secure thanks to stripe",
                    faq_q2: "How do you handle security vulnerabilities discovered in your software ?",
                    faq_q2_answer: "We have rigorous vulnerability management process that includes constantly monitoring for threats, promply implementing security patches, and transparently communicating with our users about security issues and available solutions.",                   
                    faq_q3: "How do you ensure the security of financial transactions carried out through your software ?",
                    faq_q3_answer: "We ensure transaction security using Stripe, a PCI DSS Level 1 certified payement service provider. All transactions are encrypted via SSL/TLS, ensuring data confidentiality. Stripe manages PCI DSS compliance, reducing the risk of security breaches, and offers advanced fraud detection tools to protect against fraudulent transactions",
                    faq_q4: "How much does Live Show cost?",
                    faq_q4_answer: "Live Show offers a free version for life! If you want to go even further, we offer a subscription at €19.99 per month.",
                    faq_q5: "How long does it take to join Live Show?",
                    faq_q5_answer: "You can join Live Show in just 5 minutes! Simply download the app, create an account, and you're good to go.",
                    faq_q6: "What's the purpose of joining Live Show?",
                    faq_q6_answer: "Joining Live Show allows you to create interaction between you and your community. Create engagement like you've never seen before with Live Show!",

                    /* Pricing */
                    monthly:"Monthly",
                    limit_actions:"6 Actions",
                    limit_sounds:"10 Uploads Sounds",
                    limit_widgets:"1 Widget per type",
                    limit_stream_keys:"4 Stream Keys",
                    unlimited_actions:"Unlimited Actions",
                    unlimited_sounds:"Unlimited Uploads Sounds",
                    unlimited_widgets:"Unlimited Widgets",
                    unlimited_stream_keys:"Unlimited Stream Keys",
                    live_match_gaming:"Live Match Gaming",
                    no_cb_ask:"No credit card required",
                    free_usage:"Free Usage",
                    premium:"Premium",
                    choose_plan: "Choose Plan",
                    premium_user: "Premium user",
                    offer_without_engagement: "Offers without commitment. You can unsubscribe at any time with 1 click in your member area",
                    free: "FREE",
                    for_life: "For Life",

                    /* Slider */
                    title_game_slider:"<strong>Interactive</strong> games available",

                    /* funfact */
                    satisfied_clients:"Satisfied Clients",
                    of_diamonds:"Of diamonds",
                    viewers_on_your_live:"Viewers on your Live",
                    Membership:"Membership",

                    /* Need Help */
                    need_help:"Need Help ?",
                    support:"Support",
                    join_hundred_of_users: "Join hundreds of users",

                    /* why-choose-us-2 */
                    check_pricing: "Check Pricing",
                    live_from_your_live:"Live from your live ?",
                    easy_liveshow_its_simple:"with Live Show it's simple",
                    enhance_interaction:"Enhance Interaction",
                    enhance_interaction_p:"Live Show enables users to set up personalized alerts, such as follow and subscription alerts, to encourage interaction with their live content.",
                    foster_engagement:"Foster Engagement",
                    foster_engagement_p:"With features like live streaming goals and rewards, Live Show incentivizes viewers to actively participate in live streams, thereby boosting overall engagement.",
                    facilitate_collaboration:"Facilitate Collaboration",
                    facilitate_collaboration_p:"Live Show's multi-guest feature makes it easy for content creators to collaborate, promoting interactions between different users and expanding potential audiences.",
                
                    /* cta-12 */
                    cta12_title:"Work With Live Show",
                    cta12_p:"Download Live Show right now and start working with the best software to create <br /> interactions with your community",

                    /* sign-in */
                    join_us: "Join Us Now !",
                    username: "Username",
                    password: "Password",
                    confirm_password:"Confirm Password",
                    email: "E-mail",
                    sign_up: "Sign Up",
                    learn_user_license_agreement:"Learn Terms of use",
                    account_created_successfully:"Account created successfully",
                    
                    /* Live Online */
                    banner_liveonline_title_1: "Experience the Moment",
                    banner_liveonline_title_2: "Watch Streams with Live Show",

                    /* video-v2 */
                    title_video_v2: "Discover the <strong>video</strong> demo",
                    sign_up_now: "Sign up now!",

                    /* pricing-lp */
                    title_pricing_lp: "Discover our <strong>offers</strong>",
                    button_pricing: "Start the journey",

                    /* why-choose-us-v3 */
                    why_choose_us_v3_title: "Enhance your satisfaction <br/> <strong>live at any time.</strong>",
                    why_choose_us_v3_description: "<strong>Live Show</strong> lets you <strong>reinvent your broadcasts</strong> <br/> <strong>live</strong> with our software. Powerful customization,<br/> unique interactions. <strong>Discover the new world of streaming.</strong>",
                    why_choose_us_v3_game_1: "Minecraft - TNT Rain",
                    why_choose_us_v3_game_2: "GTA V - Mount Chiliad",
                    why_choose_us_v3_game_3: "Left 4 Dead 2 - Zombie",

                    /* about-v7 */
                    about_v7_title: "Make a living from your live streams? <br/> with Live Show <br/> it's simple",
                    about_v7_subtitle_1: "Enhance interaction",
                    about_v7_subtitle_2: "Foster engagement",
                    about_v7_subtitle_3: "Facilitate collaboration",
                    about_v7_decription_1: "Live Show allows users to <strong>set up custom alerts</strong>, such as <strong>follow and subscription alerts</strong>, to encourage interaction with their live content.",
                    about_v7_decription_2: "With features like <strong>live streaming goals and rewards</strong>, Live Show encourages viewers to actively participate in live streams, <strong>thus increasing overall engagement</strong>.",
                    about_v7_decription_3: "Live Show's <strong>multi-guest feature</strong> facilitates collaboration between content creators, fostering interactions among different users and <strong>expanding potential audiences</strong>.",
                    
                    why_choose_us_v4_title: "We offer the best software <br/> <strong>for your interactions</strong>",
                    why_choose_us_v4_description: "Live Show allows you to reinvent your live broadcasts with our software. Powerful customization, unique interactions. <br/> Discover the new world of streaming.",
                    why_choose_us_v4_navbar_1: "Seamless Integration",
                    why_choose_us_v4_navbar_1_description: "<strong>Easily integrate interactive widgets</strong> into your broadcasts to captivate your audience.",
                    why_choose_us_v4_navbar_2: "Enhanced Viewer Experience",
                    why_choose_us_v4_navbar_2_description: "<strong>Offer your viewers an immersive and personalized experience</strong> to increase their engagement and loyalty.",

                    what_interactive_game: "What is an interactive game?",
                    what_interactive_game_desc: "An interactive game on TikTok is a form of video content where creators encourage viewers to interact with the video in a playful and participatory manner. These games can take various forms, including challenges, quizzes, puzzles, riddles, or even interactive stories.",
                
                    /* ruban-top */
                    text_left: "🔥 <strong> Reinvent your live broadcasts </strong> with our software 🔥",
                    follow_us: "<strong> Follow us </strong> on social media!",

                }
            },
            fr: {
                translation: {
                    /* navbar */
                    home: "Accueil",
                    about_us: "À propos",
                    download: "Télécharger",
                    pricing: "Tarif",
                    news: "Actualités",
                    faq: "FAQ",
                    ask_questions: "Poser des Questions",
                    language:"Langue",
                    create_account:"Créer un compte",
                    
                    /* footer */
                    feature: "Fonctionnalités",
                    terms_of_use: "Conditions d'Utilisation",
                    privacy_policy: "Politique de Confidentialité",
                    refund_policy: "Politique de Remboursement",
                    company:"Entreprise",
                    footer_banner_p:"Réinventez vos diffusions en direct avec notre logiciel. Une personnalisation puissante, des interactions uniques.",

                    /* why-choose-us */
                    why_choose_us:"Pourquoi Nous Choisir",
                    next_level:"Next Level",
                    next_level_sentence:"Passez au NIVEAU SUIVANT avec votre communauté grâce à Live Show",
                    next_level_p:"Live Show propose de multiples intégrations comme Minecraft, simulations de frappes, TTS, alertes sonores et plus encore.",
                    crazy_lives:"Crazy Lives",
                    crazy_lives_sentence:"Rendez votre audience complètement FOLLE lors de vos LIVES !",
                    crazy_lives_p:"Live Show est conçu pour offrir une expérience fluide et conviviale, en faisant un outil intuitif et simple pour répondre à vos besoins.",
                    game_changer:"Game Changer",
                    game_changer_sentence:"Live Show, l'outil qui change la donne pour vos lives",
                    game_changer_p:"Live Show propose un tableau de bord puissant, vous permettant de voir votre chat, votre fil d'actualités et bien plus encore.",

                    /* cta-v10 */
                    cta10_title:"Commencez avec Live Show",
                    cta10_p:"Téléchargez Live Show dès maintenant et commencez à travailler avec le meilleur logiciel pour interagir avec votre communauté",

                    /* testimonial-style-one */
                    clients_say:"Ce que disent les clients",
                    clients_say2_1 : "Nos utilisateurs <strong>témoignent</strong>",
                    know_everything_about:"Tout savoir sur",
                    client1_role:"Streameur TikTok",
                    client1_p: "Logiciel rapide et intuitif, visuellement propre et organisé avec un tableau de bord accessible.",
                    client2_p: "Superbe application interactive pour dynamiser vos lives, fortement recommandée à tous, à télécharger sans hésiter !",
                    client3_p: "Excellente application pratique avec de nombreuses fonctionnalités, mises à jour régulières et traduction complète en français.",
                  
                    /* about-style-one */
                    our_features:"Nos Fonctionnalités",
                    our_features_title:"Améliorez votre satisfaction en direct à toute heure.",
                    our_features_p:"Live Show, l'incroyable outil de diffusion en direct sur TikTok et Twitch, offre une expérience immersive pour les streamers. Avec des widgets interactifs et la possibilité pour les spectateurs de contrôler le studio en direct, Live Show élève le niveau d'interaction en direct sur TikTok. Des fonctionnalités comme l'ajout de pluie TNT aux diffusions en direct de Minecraft démontrent la polyvalence de Live Show dans la création de contenus captivants.",

                    /* about-style-two */
                    what_we_do:"Ce Que Nous Faisons",
                    what_we_do_title:"Nous Offrons Le Meilleur Logiciel Pour Vos Interactions",
                    what_we_do_p_0:"Live Show vous permet de réinventer vos diffusions en direct avec notre logiciel. Une personnalisation puissante, des interactions uniques. Découvrez le nouveau monde du streaming.",
                    what_we_do_subtitle_1:"Intégration Transparente",
                    what_we_do_p_1:"Intégrez facilement des widgets interactifs dans vos diffusions pour captiver votre audience.",
                    what_we_do_subtitle_2:"Expérience de Spectateur Améliorée",
                    what_we_do_p_2:"Rendez votre expérience de diffusion plus agréable et engageante pour les spectateurs avec un contenu dynamique.",

                    /* banner-v4 */
                    success_your_lives:"Réussissez votre Lives",
                    home_banner_title_1:"Transformez Vos Lives",
                    home_banner_title_2:"avec Live Show",
                    home_banner_p:" <strong>Live Show</strong>, l'incroyable outil de diffusion en direct sur <strong>TikTok et Twitch</strong>, offre une <strong>expérience immersive pour les streamers</strong>. Avec des widgets interactifs et la possibilité pour les spectateurs de contrôler le studio en direct, Live Show élève le niveau d'interaction en direct sur Tik Tok.",
                    home_banner_download_now:"Télécharger Maintenant",
                    start_now:"Commencer maintenant",
                    wave_bottom_1:"Augmenter l'engagement global",
                    wave_bottom_2:"Créer plus d'interactions avec vos spectateurs",
                    home_banner_info_list_1: "Améliorer <strong>l'interaction</strong>",
                    home_banner_info_list_2: "Favoriser <strong>l'engagement</strong>",
                    home_banner_info_list_3: "Faciliter <strong>la collaboration</strong>",
                    home_banner_info_list_4: "Intégration <strong>transparente</strong>",


                    /* bannière-téléchargement */
                    for_more_interactions:"pour plus d'interactions",
                    users:"Utilisateurs",
                    free_for_life:"C’est gratuit à vie :) !",

                    /* à propos de-v6 */
                    aboutv6_title:"Créer une communauté engagée",
                    aboutv6_p_0:"Live Show vous permet de créer plus d'interactions entre vous et vos spectateurs. Commencez maintenant, construisez votre propre communauté.",
                    aboutv6_subtitle_1:"Outils d'Interactions",
                    aboutv6_p_1:"Live Show permet de créer une interaction en une minute.",
                    aboutv6_subtitle_2:"Intégrations d'Applications",
                    aboutv6_p_2:"Créez des widgets, des Interactions Minecraft, des Simulations de frappes de touches.",

                    /* about */
                    about_us_title:"L'endroit parfait pour transformer votre diffusion en direct.",
                    about_us_p_0:"Live Show, l'incroyable outil de diffusion en direct sur TikTok et Twitch, offre une expérience immersive pour les streamers.",
                    about_us_li_1:"Live Show est une application gratuite, que vous pouvez télécharger via Steam!",
                    about_us_li_2:"Live Show est approuvé par de nombreux créateurs de contenu dans le monde !",


                    /*FAQ*/
                    about_our_company: "À propos de notre entreprise",
                    how_it_work: "Comment fonctionne Live Show",
                    we_are_pro: "Connaissez quelque chose de nous. Nous sommes des professionnels",
                    faq_q1: "Live Show est-il sécurisé ?",
                    faq_q1_answer: "Oui, nous accordons une attention particulière à la sécurité des données. Nous utilisons un système de hachage pour votre mot de passe et vos informations bancaires sont sécurisées grâce à Stripe.",
                    faq_q2: "Comment gérez-vous les vulnérabilités de sécurité découvertes dans votre logiciel ?",
                    faq_q2_answer: "Nous avons un processus rigoureux de gestion des vulnérabilités qui comprend une surveillance constante des menaces, la mise en œuvre rapide de correctifs de sécurité et la communication transparente avec nos utilisateurs concernant les problèmes de sécurité et les solutions disponibles.",
                    faq_q3: "Comment assurez-vous la sécurité des transactions financières effectuées via votre logiciel ?",
                    faq_q3_answer: "Nous assurons la sécurité des transactions en utilisant Stripe, un prestataire de services de paiement certifié PCI DSS de niveau 1. Toutes les transactions sont cryptées via SSL/TLS, garantissant la confidentialité des données. Stripe gère la conformité PCI DSS, réduisant ainsi le risque de violations de sécurité, et propose des outils avancés de détection de fraudes pour se protéger contre les transactions frauduleuses.",
                    faq_q4: "Combien ça coûte Live Show ?",
                    faq_q4_answer: "Live Show propose une version gratuite à vie ! Si vous voulez aller encore plus loin, nous proposons un abonnement à 19.99 € par mois.",
                    faq_q5: "J’ai besoin de combien de temps pour rejoindre Live Show ?",
                    faq_q5_answer:"Vous pouvez rejoindre Live Show en 5 minutes top chrono ! Il suffit de télécharger l'application et de vous créer un compte et c'est parti.",
                    faq_q6: "Concrètement à quoi sa sert de rejoindre Live Show ?",
                    faq_q6_answer:"Live Show vous permettra de créer de l'interaction entre vous et votre communauté. Créer un engagement comme vous ne l'avez jamais vue avec Live Show !",

                    /* Pricing */
                    monthly:"Mensuel",
                    limit_actions:"6 Actions",
                    limit_sounds:"10 Sons téléchargés",
                    limit_widgets:"1 Widget par type",
                    limit_stream_keys:"4 Clés de Stream",
                    unlimited_actions:"Actions illimitées",
                    unlimited_sounds:"Sons téléchargés illimités",
                    unlimited_widgets:"Widgets illimités",
                    unlimited_stream_keys:"Clés de flux illimitées",
                    live_match_gaming:"Match de jeu en direct",
                    no_cb_ask:"Pas de CB demandée",
                    free_usage : "Utilisation gratuite",
                    premium : "Premium",
                    choose_plan: "Choisir le plan",
                    premium_user: "Utilisateurs Premium",
                    offer_without_engagement:"Offres sans engagement. Tu peux te désabonner quand tu le souhaites en 1 clic dans ton espace membre.",
                    free: "GRATUIT",
                    for_life: "À vie",

                    /* Slider */
                    title_game_slider:"Jeux <strong>interactifs</strong> disponibles",

                    /* Funfact */
                    satisfied_clients: "Clients satisfaits",
                    of_diamonds:"De diamants",
                    viewers_on_your_live: "Spectateurs en direct",
                    Membership: "Adhésions",

                    /* Need Help */
                    need_help:"Besoin d'aide ?",
                    support:"Support",
                    join_hundred_of_users: "Rejoignez des centaines d'utilisateurs",

                    /* why-choose-us-2 */
                    check_pricing: "Découvrir nos offres",
                    live_from_your_live:"Vivre de vos lives ?",
                    easy_liveshow_its_simple: "avec Live Show c'est simple",
                    enhance_interaction: "Améliorer l'interaction",
                    enhance_interaction_p: "Live Show permet aux utilisateurs de configurer des alertes personnalisées, telles que des alertes de suivi et d'abonnement, pour encourager l'interaction avec leur contenu en direct.",
                    foster_engagement: "Favoriser l'engagement",
                    foster_engagement_p: "Avec des fonctionnalités comme les objectifs et les récompenses de streaming en direct, Live Show incite les spectateurs à participer activement aux flux en direct, augmentant ainsi l'engagement global.",
                    facilitate_collaboration: "Faciliter la collaboration",
                    facilitate_collaboration_p: "La fonctionnalité multi-invités de Live Show facilite la collaboration entre créateurs de contenu, favorisant les interactions entre différents utilisateurs et élargissant les audiences potentielles.",
                
                    /* cta-12 */
                    cta12_title:"Travailler avec Live Show",
                    cta12_p:"Téléchargez Live Show dès maintenant et commencez à travailler avec le meilleur logiciel pour créer des interactions avec votre communauté.",
                
                    /* sign-in */
                    join_us: "Rejoignez-nous maintenant !",
                    username: "Nom d'utilisateur",
                    password: "Mot de passe",
                    confirm_password:"Confirmer le mot de passe",
                    email: "E-mail",
                    sign_up: "S'inscrire",
                    learn_user_license_agreement: "Lire les conditions d'utilisations",
                    account_created_successfully:"Compte créé avec succès",

                    /* Live Online */
                    banner_liveonline_title_1: "Vivez l'Instant",
                    banner_liveonline_title_2: "Regardez les Streams avec Live Show",

                    /* video-v2 */
                    title_video_v2: "Découvrez la démo <strong>en vidéo</strong>",
                    sign_up_now: "Je m'incris maintenant !",

                    /* pricing-lp */
                    title_pricing_lp: "Découvrez nos <strong>offres</strong>",
                    button_pricing: "Commencer l'aventure",

                    /* why-choose-us-v3 */
                    why_choose_us_v3_title: "Améliorer votre satisfaction <br/> <strong>en direct à tout heure.</strong>",
                    why_choose_us_v3_description: "<strong>Live Show</strong> vous permet de <strong> réinventeur vos diffusions </strong> <br/> <strong> en direct </strong> avec notre logiciel. Un personnalisation puissance,<br/> des interactions uniques. <strong>Découvrons le nouveau monde du streaming.</strong>",
                    why_choose_us_v3_game_1: "Minecraft - Pluie de TNT",
                    why_choose_us_v3_game_2: "GTA V - Mont Chiliad",
                    why_choose_us_v3_game_3: "Left 4 Dead 2 - Zombie",

                    /* about-v7 */
                    about_v7_title: "Vivre de vos lives ? <br/> avec Live Show <br/> c'est simple",
                    about_v7_subtitle_1: "Améliorer l'interaction",
                    about_v7_subtitle_2: "Favoriser l'engagement",
                    about_v7_subtitle_3: "Faciliter la collaboration",
                    about_v7_decription_1: "Live Show permet aux utilisateurs de <strong>configurer des alertes personnalisées</strong>, telles que des <strong>alertes de suivi et d'abonnement</strong>, pour encourager l'interaction avec leur contenu en direct.",
                    about_v7_decription_2: "Avec des fonctionnalités comme les <strong>objectifs et les récompenses de streaming en direct</strong>, Live Show incite les spectateurs à participer activement aux flux en direct, <strong>augmentant ainsi l'engagement global</strong>.",
                    about_v7_decription_3: "La <strong>fonctionnalité multi-invités</strong> de Live Show facilite la collaboration entre créateurs de contenu, favorisant les interactions entre différents utilisateurs et <strong>élargissant les audiences potentielles</strong>.",
                
                    /* why-choose-us-v4 */
                    why_choose_us_v4_title: "Nous offrons le meilleur logiciel <br/> <strong>pour vos interactions</strong>",
                    why_choose_us_v4_description: "Live Show vous permet de réinventer vos diffusions en direct avec notre logiciel. Une personnalisation puissante, des interactions uniques. Découvrez le nouveau monde du streaming.",
                    why_choose_us_v4_navbar_1: "Intégration Transparente",
                    why_choose_us_v4_navbar_1_description: "<strong>Intégrez facilement des widgets interactifs</strong> dans vos diffusions pour captiver votre audience.",
                    why_choose_us_v4_navbar_2: "Expérience de spectateur améliorée",
                    why_choose_us_v4_navbar_2_description: "<strong>Offrez à vos spectateurs une expérience immersive et personnalisée</strong> pour augmenter leur engagement et fidélité.",

                    what_interactive_game: "Qu'est-ce qu'un jeu interactif ?",
                    what_interactive_game_desc: "Un jeu interactif sur Tik Tok est une forme de contenu vidéo où les créateurs incitent les spectateurs à interagir avec la vidéo de manière ludique et participative. Ces jeux peuvent prendre plusieurs formes, incluant des défis, des quiz, des puzzles, des énigmes, ou même des histoires interactives.",
                
                    /* ruban-top */
                    text_left: "🔥 <strong> Réinventez vos diffusions en direct </strong> avec notre logiciel 🔥",
                    follow_us: "<strong> Suivez-nous </strong> sur les réseaux !",
                }
            },
            it: {
                translation: {
                    /* navbar */
                    home: "Casa",
                    about_us: "Chi siamo",
                    download: "Scaricare",
                    pricing: "Prezzi",
                    news: "Notizie",
                    faq: "FAQ",
                    ask_questions: "Fare Domande",
                    language:"Linguaggio",
                    create_account:"Creare un account",
                    
                    /* footer */
                    feature: "Caratteristiche",
                    terms_of_use: "Termini di Utilizzo",
                    privacy_policy: "Informativa sulla Privacy",
                    refund_policy: "Politica di Rimborso",
                    company:"Azienda",
                    footer_banner_p:"Rivoluziona le tue trasmissioni in diretta con il nostro software. Una potente personalizzazione, interazioni uniche.",

                    /* why-choose-us */
                    why_choose_us:"Perché Come Noi",
                    next_level:"Next Level",
                    next_level_sentence:"Passa al LIVELLO SUCCESSIVO con la tua comunità grazie a Live Show",
                    next_level_p:"Live Show offre diverse integrazioni come Minecraft, simulazioni di battitura, TTS, allarmi sonori e altro ancora.",
                    crazy_lives:"Crazy Lives",
                    crazy_lives_sentence:"Fai impazzire il tuo pubblico durante i tuoi LIVE!",
                    crazy_lives_p:"Live Show è progettato per offrire un'esperienza fluida e facile da usare, rendendolo uno strumento intuitivo e semplice per soddisfare le tue esigenze.",
                    game_changer:"Game Changer",
                    game_changer_sentence:"Live Show, lo strumento che cambia le carte in tavola per i tuoi live",
                    game_changer_p:"Live Show offre un potente dashboard che ti consente di visualizzare la tua chat, il tuo feed di notizie e molto altro.",
                
                    /* cta-v10 */
                    cta10_title:"Inizia con Live Show",
                    cta10_p:"Scarica Live Show subito e inizia a lavorare con il miglior software per creare interazioni con la tua comunità",

                    /* testimonial-style-one */
                    clients_say:"Cosa dicono i clienti",
                    clients_say2_1: "I nostri utenti <strong>testimoniano</strong>",
                    know_everything_about:"Scopri tutto su",
                    client1_role:"TikToker",
                    client1_p: "Software rapido e intuitivo, visivamente pulito e organizzato con un dashboard accessibile.",
                    client2_p: "Superba applicazione interattiva per vivacizzare le tue dirette, altamente raccomandata a tutti, da scaricare senza esitazione!",
                    client3_p: "Eccellente applicazione pratica con molte funzionalità, aggiornamenti regolari e traduzione completa in italiano.",
                  
                    /* about-style-one */
                    our_features:"Le nostre Caratteristiche",
                    our_features_title:"Migliora la tua soddisfazione dal vivo in ogni momento.",
                    our_features_p:"Live Show, l'incredibile strumento di streaming live su TikTok e Twitch, offre un'esperienza coinvolgente per gli streamer. Con widget interattivi e la possibilità per gli spettatori di controllare lo studio live, Live Show eleva il livello di interazione live su TikTok. Funzionalità come l'aggiunta della pioggia TNT alle trasmissioni live di Minecraft dimostrano la versatilità di Live Show nella creazione di contenuti accattivanti.",

                    /* about-style-two */
                    what_we_do:"Cosa Facciamo",
                    what_we_do_title:"Offriamo Il Miglior Software Per Le Tue Interazioni",
                    what_we_do_p_0:"Live Show ti permette di reinventare le tue trasmissioni live con il nostro software. Una personalizzazione potente, interazioni uniche. Scopri il nuovo mondo dello streaming.",
                    what_we_do_subtitle_1:"Integrazione senza soluzione di continuità",
                    what_we_do_p_1:"Integra facilmente widget interattivi nelle tue trasmissioni per catturare il tuo pubblico.",
                    what_we_do_subtitle_2:"Esperienza Migliorata per gli Spettatori",
                    what_we_do_p_2:"Rendi la tua esperienza di streaming più piacevole e coinvolgente per gli spettatori con contenuti dinamici.",

                    /* banner-v4 */
                    success_your_lives:"Rendetele vostre Lives un successo",
                    home_banner_title_1:"Trasforma le Tue Lives",
                    home_banner_title_2:"con Live Show",
                    home_banner_p: "<strong>Live Show</strong>, l'incredibile strumento di trasmissione in diretta su <strong>TikTok e Twitch</strong>, offre un'<strong>esperienza immersiva per gli streamer</strong>. Con widget interattivi e la possibilità per gli spettatori di controllare lo studio in diretta, Live Show eleva il livello di interazione in diretta su TikTok.",
                    home_banner_download_now:"Scarica Ora",
                    start_now:"Inizia ora",
                    wave_bottom_1: "Aumentare il coinvolgimento globale",
                    wave_bottom_2: "Crea più interazioni con i tuoi spettatori",
                    home_banner_info_list_1: "Migliorare <strong>l'interazione</strong>",
                    home_banner_info_list_2: "Favorire <strong>il coinvolgimento</strong>",
                    home_banner_info_list_3: "Facilitare <strong>la collaborazione</strong>",
                    home_banner_info_list_4: "Integrazione <strong>trasparente</strong>",


                    /* bannière-téléchargement */
                    for_more_interactions:"per più interazioni",
                    users:"Utenti",
                    free_for_life:"È gratis a vita :) !",

                    /* à propos de-v6 */
                    aboutv6_title:"Creare una community impegnata",
                    aboutv6_p_0:"Live Show ti permette di creare più interazione tra te e i tuoi spettatori. Inizia ora, costruisci la tua comunità.",
                    aboutv6_subtitle_1:"Strumenti di Interazione",
                    aboutv6_p_1:"Live Show permette di creare un'interazione in un minuto.",
                    aboutv6_subtitle_2:"Integrazioni delle App",
                    aboutv6_p_2:"Crea widget, Interazioni Minecraft, Simulazioni di tasti.",

                    /* about */
                    about_us_title:"Il posto perfetto per trasformare la tua diretta streaming.",
                    about_us_p_0:"Live Show, lo straordinario strumento di streaming live su TikTok e Twitch, offre un'esperienza immersiva per gli streamer.",
                    about_us_li_1:"Live Show è un'applicazione gratuita, puoi scaricarla tramite Steam!",
                    about_us_li_2:"Live Show è utilizzato da molti creatori di contenuti nel mondo!",
                    
                    /*FAQ*/
                    about_our_company: "La nostra azienda",
                    how_it_work: "Come funziona Live Show",
                    we_are_pro: "Conoscere qualcosa di noi. Siamo professionisti",
                    faq_q1: "Live Show è sicuro?",
                    faq_q1_answer: "Sì, prestiamo particolare attenzione alla sicurezza dei dati. Utilizziamo un sistema di hash per la tua password e le informazioni bancarie sono protette grazie a Stripe.",
                    faq_q2: "Come gestite le vulnerabilità scoperte nel vostro software?",
                    faq_q2_answer: "Abbiamo un rigoroso processo di gestione delle vulnerabilità che include il monitoraggio costante delle minacce, l'implementazione tempestiva di patch di sicurezza e la comunicazione trasparente con i nostri utenti riguardo ai problemi di sicurezza e alle soluzioni disponibili.",
                    faq_q3: "Come garantite la sicurezza delle transazioni finanziarie effettuate tramite il vostro software?",
                    faq_q3_answer: "Assicuriamo la sicurezza delle transazioni utilizzando Stripe, un fornitore di servizi di pagamento certificato PCI DSS di livello 1. Tutte le transazioni sono crittografate tramite SSL/TLS, garantendo la riservatezza dei dati. Stripe gestisce la conformità PCI DSS, riducendo il rischio di violazioni di sicurezza, e offre strumenti avanzati di rilevamento delle frodi per proteggere dalle transazioni fraudolente.",
                    faq_q4: "Quanto costa Live Show ?",
                    faq_q4_answer: "Live Show offre una versione gratuita a vita! Se desideri andare oltre, offriamo un abbonamento a 19,99 € al mese.",                    
                    faq_q5: "Quanto tempo ci vuole per unirsi a Live Show?",
                    faq_q5_answer: "Puoi unirti a Live Show in 5 minuti al massimo! Basta scaricare l'applicazione e creare un account, ed è fatta.",                    
                    faq_q6: "Qual è lo scopo di unirsi a Live Show?",
                    faq_q6_answer: "Unirsi a Live Show ti permette di creare interazione tra te e la tua comunità. Crea coinvolgimento come non hai mai visto prima con Live Show!",

                    /* Pricing */
                    monthly:"Mensile",
                    limit_actions:"6 Azioni",
                    limit_sounds:"10 Suoni caricati",
                    limit_widgets:"1 Widget per tipo",
                    limit_stream_keys:"4 Chiavi di flusso",
                    unlimited_actions:"Azioni illimitate",
                    unlimited_sounds:"Suoni caricati illimitati",
                    unlimited_widgets:"Widget illimitati",
                    unlimited_stream_keys:"Chiavi di flusso illimitate",
                    live_match_gaming:"Partita di gioco in diretta",
                    no_cb_ask:"Non è richiesta la carta di credito",
                    free_use: "Uso gratuito",
                    premium: "Premium",
                    choose_plan: "Scegliere un piano",
                    premium_user: "Utilizzatore Premium",
                    offer_without_engagement:"Offerte non vincolanti. È possibile annullare l'iscrizione in qualsiasi momento con un clic nella propria area riservata.",
                    free: "GRATUITO",
                    for_life: "Per la vita",

                    /* Slider */
                    title_game_slider:"Giochi <strong>interattivi</strong> disponibili",

                    /* Funfact */
                    satisfied_clients: "Clienti soddisfatti",
                    of_diamonds:"Di diamanti",
                    viewers_on_your_live: "Spettatori dal vivo",
                    Membership: "Membri",

                    /* Need Help */
                    need_help:"Hai bisogno di aiuto?",
                    support:"Supporto",
                    join_hundred_of_users: "Unitevi a centinaia di utenti",

                    /* why-choose-us-2 */
                    check_pricing: "Controlla i prezzi",
                    live_from_your_live: "Dal vivo dai tuoi spettacoli?",
                    easy_liveshow_its_simple: "con Live Show è semplice",
                    enhance_interaction: "Potenziare l'interazione",
                    enhance_interaction_p: "Live Show permette agli utenti di impostare avvisi personalizzati, come avvisi di follow e di abbonamento, per incoraggiare l'interazione con il loro contenuto dal vivo.",
                    foster_engagement: "Favorire l'impegno",
                    foster_engagement_p: "Con funzionalità come obiettivi di streaming dal vivo e ricompense, Live Show incentiva gli spettatori a partecipare attivamente agli streaming, aumentando così l'impegno complessivo.",
                    facilitate_collaboration: "Facilitare la collaborazione",
                    facilitate_collaboration_p: "La funzione multi-ospite di Live Show rende facile per i creatori di contenuti collaborare, promuovendo interazioni tra diversi utenti ed espandendo il pubblico potenziale.",
                
                    /* cta-12 */
                    cta12_title:"Lavorare con il Live Show",
                    cta12_p:"Scaricate subito Live Show e iniziate a lavorare con il miglior software per creare interazione con la vostra comunità.",
                
                    /* sign-in */
                    join_us: "Unisciti a noi adesso !",
                    username: "Nome utente",
                    password: "Password",
                    confirm_password:"Conferma password",
                    email: "E-mail",
                    sign_up: "Registrati",
                    learn_user_license_agreement: "Scopri le condizioni di utilizzo",
                    account_created_successfully:"Account creato con successo",
                
                    /* Live Online */
                    banner_liveonline_title_1: "Vivi il Momento",
                    banner_liveonline_title_2: "Guarda le Dirette con Live Show",

                    /* video-v2 */
                    title_video_v2: "Scopri la demo <strong>video</strong>",
                    sign_up_now: "Iscriviti ora!",

                    /* pricing-lp */
                    title_pricing_lp: "Scopri le nostre <strong>offerte</strong>",
                    button_pricing: "Inizia il viaggio",

                    /* why-choose-us-v3 */
                    why_choose_us_v3_title: "Migliora la tua soddisfazione <br/> <strong>in diretta in qualsiasi momento.</strong>",
                    why_choose_us_v3_description: "<strong>Live Show</strong> ti permette di <strong>reinventare le tue trasmissioni</strong> <br/> <strong>in diretta</strong> con il nostro software. Personalizzazione potente,<br/> interazioni uniche. <strong>Scopri il nuovo mondo dello streaming.</strong>",
                    why_choose_us_v3_game_1: "Minecraft - Pioggia di TNT",
                    why_choose_us_v3_game_2: "GTA V - Monte Chiliad",
                    why_choose_us_v3_game_3: "Left 4 Dead 2 - Zombie",

                    /* about-v7 */
                    about_v7_title: "Vivere dei tuoi live? <br/> con Live Show <br/> è semplice",
                    about_v7_subtitle_1: "Migliora l'interazione",
                    about_v7_subtitle_2: "Favorisci il coinvolgimento",
                    about_v7_subtitle_3: "Facilita la collaborazione",
                    about_v7_decription_1: "Live Show permette agli utenti di <strong>configurare avvisi personalizzati</strong>, come <strong>avvisi di follow e abbonamento</strong>, per incoraggiare l'interazione con il loro contenuto in diretta.",
                    about_v7_decription_2: "Con funzionalità come <strong>obiettivi e ricompense del live streaming</strong>, Live Show incoraggia gli spettatori a partecipare attivamente alle dirette, <strong>aumentando così il coinvolgimento complessivo</strong>.",
                    about_v7_decription_3: "La <strong>funzionalità multi-ospite</strong> di Live Show facilita la collaborazione tra i creatori di contenuti, favorendo le interazioni tra diversi utenti e <strong>espandendo il potenziale pubblico</strong>.",
                    
                    why_choose_us_v4_title: "Offriamo il miglior software <br/> <strong>per le tue interazioni</strong>",
                    why_choose_us_v4_description: "Live Show ti permette di reinventare le tue trasmissioni in diretta con il nostro software. Personalizzazione potente, <br/> interazioni uniche. Scopri il nuovo mondo dello streaming.",
                    why_choose_us_v4_navbar_1: "Integrazione Senza Soluzione di Continuità",
                    why_choose_us_v4_navbar_1_description: "<strong>Integra facilmente widget interattivi</strong> nelle tue trasmissioni per catturare il tuo pubblico.",
                    why_choose_us_v4_navbar_2: "Esperienza Spettatore Migliorata",
                    why_choose_us_v4_navbar_2_description: "<strong>Offri ai tuoi spettatori un'esperienza immersiva e personalizzata</strong> per aumentare il loro coinvolgimento e fedeltà",

                    what_interactive_game: "Che cos'è un gioco interattivo?",
                    what_interactive_game_desc: "Un gioco interattivo su TikTok è una forma di contenuto video in cui i creatori incoraggiano gli spettatori a interagire con il video in modo ludico e partecipativo. Questi giochi possono assumere diverse forme, tra cui sfide, quiz, puzzle, enigmi o anche storie interattive.",
                
                    /* ruban-top */
                    text_left: "🔥 <strong> Reinventa le tue trasmissioni in diretta </strong> con il nostro software 🔥",
                    follow_us: "<strong> Seguici </strong> sui social media!",

                }
            }
        }
    })

export default i18n;